function setupTable() {
  console.log('test')
  var table = $('.results-dt');
  let dataT = table.DataTable({
    // scrollY: "600px",
    scrollX: true,
    scrollCollapse: true,
  });
}

function clearTable() {
  $('.results-dt').DataTable().destroy();
}

document.addEventListener('turbolinks:load', setupTable)
document.addEventListener("turbolinks:before-cache", clearTable)
